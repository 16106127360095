import {
  type PayloadAction,
  createSlice,
  type ThunkAction,
  type Action,
} from '@reduxjs/toolkit'
import { setMemento, type BoardState, selectMemento } from '../board/boardSlice'
import { type RootState } from '../../app/store'
import {
  type IHistoryElement,
  type IHistoryState,
} from '../../types/historyTypes'

export interface HistoryState {
  // Contains all the past board state and moves that have been made
  history: IHistoryElement[]
  // This is the last board state that is not yet in history and is the current board state
  lastBoardState: BoardState | null
  // Points to the next free position in history, i.e. the next future move (null means not initialized)
  positionInHistory: number
}

const initialState: HistoryState = {
  history: [],
  lastBoardState: null,
  positionInHistory: 0,
}

export const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    saveToHistory: (state, action: PayloadAction<IHistoryElement>) => {
      // Invalidate the last board state
      state.lastBoardState = null

      // Check if some adaptions to the history have to be made
      if (state.positionInHistory < state.history.length) {
        // This means that there are some entries that have been undone and are now the be overwritten
        // Remove all the entries in history after the current pointer
        state.history.splice(state.positionInHistory)
      }

      // The new entry can now be inserted to the history
      state.history[state.positionInHistory] = action.payload

      // Update the pointer to the current position in history
      state.positionInHistory++
    },
    saveLastBoardState: (state, action: PayloadAction<BoardState>) => {
      state.lastBoardState = action.payload
    },
    undoMoveInHistory: state => {
      // Check if the undo is possible
      if (!canUndoMove(state)) {
        throw new Error('Undo not possible! Check before calling the action')
      }

      // Adapt the position of the pointer in history
      state.positionInHistory--
    },
    redoMoveInHistory: state => {
      // Check if the redo is possible
      if (!canRedoMove(state)) {
        throw new Error('Redo not possible! Check before calling the action')
      }

      // Adapt the position of the pointer in history
      state.positionInHistory++
    },
    resetHistory: state => {
      state.history = []
      state.lastBoardState = null
      state.positionInHistory = 0
    },
    setLoadedHistory: (state, action: PayloadAction<IHistoryState>) => {
      state.history = action.payload.history
      state.lastBoardState = action.payload.lastBoardState
      state.positionInHistory = action.payload.positionInHistory
    },
  },
})

/**
 * Export actions
 */
export const {
  saveToHistory,
  saveLastBoardState,
  resetHistory,
  setLoadedHistory,
} = historySlice.actions

/**
 * Non exported actions
 */
const { undoMoveInHistory, redoMoveInHistory } = historySlice.actions

/**
 * Thunk functions
 * Need thunk functions for undo/redo since we need to dispatch two actions one after the other
 */
export const undoMove =
  (): ThunkAction<void, RootState, unknown, Action> =>
  async (dispatch, getState) => {
    // If we are undoing the last move in the history, we have to create a memento of the current board state and save it
    if (
      getState().history.positionInHistory === getState().history.history.length
    ) {
      // Get the memento and store it
      const memento: BoardState = selectMemento(getState())
      dispatch(saveLastBoardState(memento))
    }

    try {
      // Try to dispatch the move for the history
      dispatch(undoMoveInHistory())

      // On success update the board state
      const currentBoardState = selectCurrentBoardState(getState())
      if (currentBoardState !== null) {
        dispatch(setMemento(currentBoardState))
      }
    } catch (e) {
      console.error(e)
    }
  }

export const redoMove =
  (): ThunkAction<void, RootState, unknown, Action> =>
  async (dispatch, getState) => {
    try {
      // Try to dispatch the move for the history
      dispatch(redoMoveInHistory())

      // On success udate the board state
      const currentBoardState = selectCurrentBoardState(getState())
      if (currentBoardState !== null) {
        dispatch(setMemento(currentBoardState))
      }
    } catch (e) {
      console.error(e)
    }
  }

/**
 * Export selectors
 */
export const selectHistory = (state: RootState): IHistoryElement[] =>
  state.history.history

export const selectPositionInHistory = (state: RootState): number =>
  state.history.positionInHistory

export const selectCurrentBoardState = (
  state: RootState,
): BoardState | null => {
  if (state.history.history.length === 0) return null

  if (state.history.positionInHistory < 0) return null

  // If we are redoing the last move, we have to use the last board state
  if (state.history.positionInHistory === state.history.history.length) {
    return state.history.lastBoardState
  }

  return state.history.history[state.history.positionInHistory].boardState
}

const canUndoMove = (state: HistoryState): boolean => {
  return state.positionInHistory > 0
}
export const selectCanUndoMove = (state: RootState): boolean => {
  return canUndoMove(state.history)
}

const canRedoMove = (state: HistoryState): boolean => {
  return state.positionInHistory < state.history.length
}
export const selectCanRedoMove = (state: RootState): boolean => {
  return canRedoMove(state.history)
}

/**
 * Export default reducer
 */
export default historySlice.reducer
