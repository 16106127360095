import React, { useEffect } from 'react'
import './App.css'
import './styles/global.scss'

/* Fonts for material UI */
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import Board from './features/board/board'
import Title from './components/title/title'
import Template from './components/template/template'

function App(): React.JSX.Element {
  // Show warning before reload
  useEffect(() => {
    const unloadCallback = (event: {
      preventDefault: () => void
      returnValue: string
    }): string => {
      event.preventDefault()
      event.returnValue = ''
      return ''
    }

    window.addEventListener('beforeunload', unloadCallback)
    return () => {
      window.removeEventListener('beforeunload', unloadCallback)
    }
  }, [])

  return (
    <Template>
      <Title>Nine Men&apos;s Morris</Title>
      <Board />
    </Template>
  )
}

export default App
