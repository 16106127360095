import React from 'react'
import './title.scss'

interface IProps {
  children?: any
}

const Title = (props: IProps): React.JSX.Element => {
  return (
    <div className="title-container">
      <h1>{props.children}</h1>
    </div>
  )
}

export default Title
