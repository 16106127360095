/**
 * This file contains some helper functions
 * that can be used to check certain conditions
 * on th board and in the game
 */

import { type IFieldInformation, Player } from '../types/fieldTypes'
import {
  BoardCircle,
  BoardField,
  Direction,
  GameState,
  type IBoard,
  type IPosition,
} from '../types/boardTypes'
import { type BoardState } from '../features/board/boardSlice'
import {
  PIECES_FOR_MILL,
  PIECES_PER_PLAYER,
  PIECES_REMOVED_FOR_FLYING,
} from '../constants/gameConstants'
import { MoveType, type IMove } from '../types/moveTypes'
import React from 'react'

/**
 * Returns the field information for the given position
 *
 * @param position The position to get the field information for
 * @param board The board to get the field information from
 */
const getFieldInformation = (props: {
  position: IPosition
  board: IBoard
}): IFieldInformation => {
  return props.board[props.position.circle][props.position.field]
}

/**
 * Returns the position of the field that is adjacent to the given position
 * on the top side
 *
 * @param position The position to get the adjacent field for
 */
const getTopAdjacentPosition = (position: IPosition): IPosition | null => {
  const adjacentPosition: IPosition = {
    circle: position.circle,
    field: position.field,
  }

  if (position.field === BoardField.CenterLeft) {
    // There is a jump, so simple numeric addition is not possible
    adjacentPosition.field = BoardField.TopLeft
  } else if (position.field === BoardField.BottomLeft) {
    // Moving to the top on the left line of the circle
    adjacentPosition.field += 1
  } else if (
    position.field === BoardField.CenterRight ||
    position.field === BoardField.BottomRight
  ) {
    // Moving to the top on the right line of the circle
    adjacentPosition.field -= 1
  } else if (
    position.field === BoardField.BottomCenter &&
    position.circle < BoardCircle.Inner
  ) {
    // Moving to the top from an outer circle to an inner circle
    adjacentPosition.circle += 1
  } else if (
    position.field === BoardField.TopCenter &&
    position.circle > BoardCircle.Outer
  ) {
    // Moving to the top from an inner circle to an outer circle
    adjacentPosition.circle -= 1
  } else {
    // Returning null if there is no adjacent field
    return null
  }

  return adjacentPosition
}

/**
 * Returns the position of the field that is adjacent to the given position
 * on the right side
 *
 * @param position The position to get the adjacent field for
 */
const getRightAdjacentPosition = (position: IPosition): IPosition | null => {
  const adjacentPosition: IPosition = {
    circle: position.circle,
    field: position.field,
  }

  if (
    position.field === BoardField.TopLeft ||
    position.field === BoardField.TopCenter
  ) {
    // Moving to the right on the top line of the circle
    adjacentPosition.field += 1
  } else if (
    position.field === BoardField.BottomLeft ||
    position.field === BoardField.BottomCenter
  ) {
    // Moving to the right on the bottom line of the circle
    adjacentPosition.field -= 1
  } else if (
    position.field === BoardField.CenterLeft &&
    position.circle < BoardCircle.Inner
  ) {
    // Moving to the right from an outer circle to an inner circle
    adjacentPosition.circle += 1
  } else if (
    position.field === BoardField.CenterRight &&
    position.circle > BoardCircle.Outer
  ) {
    // Moving to the right from an inner circle to an outer circle
    adjacentPosition.circle -= 1
  } else {
    // Returning null if there is no adjacent field
    return null
  }

  return adjacentPosition
}

/**
 * Returns the position of the field that is adjacent to the given position
 * on the bottom side
 *
 * @param position The position to get the adjacent field for
 */
const getBottomAdjacentPosition = (position: IPosition): IPosition | null => {
  const adjacentPosition: IPosition = {
    circle: position.circle,
    field: position.field,
  }

  if (position.field === BoardField.TopLeft) {
    // There is a jump, so simple numeric addition is not possible
    adjacentPosition.field = BoardField.CenterLeft
  } else if (position.field === BoardField.CenterLeft) {
    // Moving to the bottom on the left line of the circle
    adjacentPosition.field -= 1
  } else if (
    position.field === BoardField.TopRight ||
    position.field === BoardField.CenterRight
  ) {
    // Moving to the bottom on the right line of the circle
    adjacentPosition.field += 1
  } else if (
    position.field === BoardField.TopCenter &&
    position.circle < BoardCircle.Inner
  ) {
    // Moving to the bottom from an outer circle to an inner circle
    adjacentPosition.circle += 1
  } else if (
    position.field === BoardField.BottomCenter &&
    position.circle > BoardCircle.Outer
  ) {
    // Moving to the bottom from an inner circle to an outer circle
    adjacentPosition.circle -= 1
  } else {
    // Returning null if there is no adjacent field
    return null
  }

  return adjacentPosition
}

/**
 * Returns the position of the field that is adjacent to the given position
 * on the left side
 *
 * @param position The position to get the adjacent field for
 */
const getLeftAdjacentPosition = (position: IPosition): IPosition | null => {
  const adjacentPosition: IPosition = {
    circle: position.circle,
    field: position.field,
  }

  if (
    position.field === BoardField.TopCenter ||
    position.field === BoardField.TopRight
  ) {
    // Moving to the left on the top line of the circle
    adjacentPosition.field -= 1
  } else if (
    position.field === BoardField.BottomCenter ||
    position.field === BoardField.BottomRight
  ) {
    // Moving to the left on the bottom line of the circle
    adjacentPosition.field += 1
  } else if (
    position.field === BoardField.CenterRight &&
    position.circle < BoardCircle.Inner
  ) {
    // Moving to the left from an outer circle to an inner circle
    adjacentPosition.circle += 1
  } else if (
    position.field === BoardField.CenterLeft &&
    position.circle > BoardCircle.Outer
  ) {
    // Moving to the left from an inner circle to an outer circle
    adjacentPosition.circle -= 1
  } else {
    // Returning null if there is no adjacent field
    return null
  }

  return adjacentPosition
}

/**
 * Returns the position of the field that is adjacent to the given position
 * in the given direction
 *
 * @param position The position to get the adjacent field for
 * @param direction The direction in which the adjacent field should be
 */
const getAdjacentPosition = (props: {
  position: IPosition | null
  direction: Direction
}): IPosition | null => {
  // If the position is null, there is no adjacent field
  if (props.position === null) {
    return null
  }

  // Returning the adjacent field for the defined direction
  switch (props.direction) {
    case Direction.Top:
      return getTopAdjacentPosition(props.position)
    case Direction.Right:
      return getRightAdjacentPosition(props.position)
    case Direction.Bottom:
      return getBottomAdjacentPosition(props.position)
    case Direction.Left:
      return getLeftAdjacentPosition(props.position)
  }
}

/**
 * Starts from the position provided and goes into the direction defined and counts how man chips of the player are on the line.
 * Stops if the line ends or if a chip of the other player is found.
 * Note: It is assumed, that the current field belongs to the player. Also, the current field is not counted.
 *
 * @param board The board to check
 * @param positionToCheck The position from where we start
 * @param player The player whoms chips should be counted
 * @param direction The direction in which we go
 */
const getNumberOfChipsOnLine = (props: {
  board: IBoard
  position: IPosition
  player: Player
  direction: Direction
}): number => {
  let numberOfChipsOnLine: number = 0
  let nextPosition: IPosition | null = props.position

  // Go into the defined direction
  while (true) {
    nextPosition = getAdjacentPosition({
      position: nextPosition,
      direction: props.direction,
    })

    if (nextPosition === null) {
      // No more adjacent fields
      break
    }

    const fieldInformation: IFieldInformation = getFieldInformation({
      position: nextPosition,
      board: props.board,
    })
    if (fieldInformation.player !== props.player) {
      // If the field does not belong to the player, we stop
      break
    }

    // Otherwise we continue
    numberOfChipsOnLine += 1
  }

  return numberOfChipsOnLine
}

/**
 * Checks if a player has a mill on the board that includes the positionToCheck field
 *
 * This function will return true if the player has more than on mill on the board that includes the positionToCheck
 *
 * Rule: If during the PLACING_PHASE the player closes two mills with one piece, the player is only allowed to take one piece of the opponent.
 * This indicates that the player can at any point in the game maximally take one piece of the oponent at once, since it is not possible to close more than one will with one piece in the MOVING or FLYING phase.
 */
const hasClosedMill = (props: {
  board: IBoard
  player: Player
  positionToCheck: IPosition
}): boolean => {
  // The positionToCheck must be occupied by the player
  if (
    props.board[props.positionToCheck.circle][props.positionToCheck.field]
      .player !== props.player
  ) {
    return false
  }

  // Check if there is a mill on a horizontal line
  let numberOfChipsOnHorizontalLine: number = 1
  numberOfChipsOnHorizontalLine += getNumberOfChipsOnLine({
    board: props.board,
    position: props.positionToCheck,
    player: props.player,
    direction: Direction.Left,
  })
  numberOfChipsOnHorizontalLine += getNumberOfChipsOnLine({
    board: props.board,
    position: props.positionToCheck,
    player: props.player,
    direction: Direction.Right,
  })

  if (numberOfChipsOnHorizontalLine === PIECES_FOR_MILL) {
    return true
  }

  // Check if there is a mill on a horizontal line
  let numberOfChipsOnVerticalLine: number = 1
  numberOfChipsOnVerticalLine += getNumberOfChipsOnLine({
    board: props.board,
    position: props.positionToCheck,
    player: props.player,
    direction: Direction.Top,
  })
  numberOfChipsOnVerticalLine += getNumberOfChipsOnLine({
    board: props.board,
    position: props.positionToCheck,
    player: props.player,
    direction: Direction.Bottom,
  })

  if (numberOfChipsOnVerticalLine === PIECES_FOR_MILL) {
    return true
  }

  // If nothing has been found, there is no mill
  return false
}

/**
 * Returns the next player whoms turn it is
 *
 * @param player The current player
 */
const getNextPlayer = (player: Player): Player => {
  if (player === Player.PlayerOne) {
    return Player.PlayerTwo
  } else {
    return Player.PlayerOne
  }
}

const isPlayerFlying = (state: BoardState): boolean => {
  const playersTurn = state.playersTurn
  return state.piecesRemoved[playersTurn] >= PIECES_REMOVED_FOR_FLYING
}

const isAdjacentField = (current: IPosition, target: IPosition): boolean => {
  const array = [
    Direction.Top,
    Direction.Bottom,
    Direction.Left,
    Direction.Right,
  ]

  for (const dir of array) {
    const found = getAdjacentPosition({
      position: current,
      direction: dir,
    })

    if (found !== null) {
      if (found.field === target.field && found.circle === target.circle) {
        return true
      }
    }
  }
  return false
}

/**
 * Returns the next game state depending on the pieces the players have
 * E.g. the state that is used when no special condition happens (e.g. closing a mill)
 *
 * @param state The current board state
 */
const getNextGameStateDependingOnPieces = (state: BoardState): GameState => {
  // Check if a transition from placing pieces to moving pieces is necessary
  if (
    state.placedPieces[Player.PlayerOne] === PIECES_PER_PLAYER &&
    state.placedPieces[Player.PlayerTwo] === PIECES_PER_PLAYER
  ) {
    // If both players have placed all their pieces, change the game state
    return GameState.SELECT_PIECE_TO_MOVE
  } else {
    // If no transition is necessary, we stay in the same state
    return GameState.PLACING_PIECES
  }
}

/**
 * Returns an empty board
 *
 * @returns IBoard
 */
const getEmptyBoard = (): IBoard => {
  const emptyField: IFieldInformation = {
    player: null,
  }

  // Initialize board statically to keep type safety for IBoard (No field in IBoard should ever be undefined)
  const board: IBoard = {
    [BoardCircle.Outer]: {
      [BoardField.TopLeft]: emptyField,
      [BoardField.TopCenter]: emptyField,
      [BoardField.TopRight]: emptyField,
      [BoardField.CenterRight]: emptyField,
      [BoardField.BottomRight]: emptyField,
      [BoardField.BottomCenter]: emptyField,
      [BoardField.BottomLeft]: emptyField,
      [BoardField.CenterLeft]: emptyField,
    },
    [BoardCircle.Middle]: {
      [BoardField.TopLeft]: emptyField,
      [BoardField.TopCenter]: emptyField,
      [BoardField.TopRight]: emptyField,
      [BoardField.CenterRight]: emptyField,
      [BoardField.BottomRight]: emptyField,
      [BoardField.BottomCenter]: emptyField,
      [BoardField.BottomLeft]: emptyField,
      [BoardField.CenterLeft]: emptyField,
    },
    [BoardCircle.Inner]: {
      [BoardField.TopLeft]: emptyField,
      [BoardField.TopCenter]: emptyField,
      [BoardField.TopRight]: emptyField,
      [BoardField.CenterRight]: emptyField,
      [BoardField.BottomRight]: emptyField,
      [BoardField.BottomCenter]: emptyField,
      [BoardField.BottomLeft]: emptyField,
      [BoardField.CenterLeft]: emptyField,
    },
  }

  return board
}

/**
 * Return the player name as string
 *
 * @param player
 *
 * @return string
 */
const getPlayerNameString = (player: Player): string => {
  if (player === Player.PlayerOne) {
    return 'Player one'
  } else {
    return 'Player two'
  }
}

const getPlayerElement = (player: Player): React.JSX.Element => {
  const className: string =
    player === Player.PlayerOne ? 'player-one' : 'player-two'
  return <strong className={className}>{getPlayerNameString(player)}</strong>
}

const mapPositionToFieldString = (position: IPosition): string => {
  let row: string = ''
  if (position.circle === BoardCircle.Outer) {
    if (
      position.field === BoardField.TopLeft ||
      position.field === BoardField.TopCenter ||
      position.field === BoardField.TopRight
    ) {
      row = '7'
    } else if (
      position.field === BoardField.CenterLeft ||
      position.field === BoardField.CenterRight
    ) {
      row = '4'
    } else {
      row = '1'
    }
  } else if (position.circle === BoardCircle.Middle) {
    if (
      position.field === BoardField.TopLeft ||
      position.field === BoardField.TopCenter ||
      position.field === BoardField.TopRight
    ) {
      row = '6'
    } else if (
      position.field === BoardField.CenterLeft ||
      position.field === BoardField.CenterRight
    ) {
      row = '4'
    } else {
      row = '2'
    }
  } else if (position.circle === BoardCircle.Inner) {
    if (
      position.field === BoardField.TopLeft ||
      position.field === BoardField.TopCenter ||
      position.field === BoardField.TopRight
    ) {
      row = '5'
    } else if (
      position.field === BoardField.CenterLeft ||
      position.field === BoardField.CenterRight
    ) {
      row = '4'
    } else {
      row = '3'
    }
  }

  let column: string = ''
  if (position.circle === BoardCircle.Outer) {
    if (
      position.field === BoardField.TopLeft ||
      position.field === BoardField.CenterLeft ||
      position.field === BoardField.BottomLeft
    ) {
      column = 'A'
    } else if (
      position.field === BoardField.TopCenter ||
      position.field === BoardField.BottomCenter
    ) {
      column = 'D'
    } else {
      column = 'G'
    }
  } else if (position.circle === BoardCircle.Middle) {
    if (
      position.field === BoardField.TopLeft ||
      position.field === BoardField.CenterLeft ||
      position.field === BoardField.BottomLeft
    ) {
      column = 'B'
    } else if (
      position.field === BoardField.TopCenter ||
      position.field === BoardField.BottomCenter
    ) {
      column = 'D'
    } else {
      column = 'F'
    }
  } else if (position.circle === BoardCircle.Inner) {
    if (
      position.field === BoardField.TopLeft ||
      position.field === BoardField.CenterLeft ||
      position.field === BoardField.BottomLeft
    ) {
      column = 'C'
    } else if (
      position.field === BoardField.TopCenter ||
      position.field === BoardField.BottomCenter
    ) {
      column = 'D'
    } else {
      column = 'E'
    }
  }

  return `${column}${row}`
}

/**
 * Returns a string that describes the move
 *
 * @param move
 *
 * @return string
 */
const getMoveString = (move: IMove): string => {
  const positionString: string = mapPositionToFieldString(move.position)

  switch (move.type) {
    case MoveType.PLACE_CHIP:
      return `Placed chip on ${positionString}`
    case MoveType.SELECT_CHIP_TO_MOVE:
      return `Selected chip on ${positionString} for move`
    case MoveType.MOVE_CHIP:
      return `Moved selected chip to ${positionString}`
    case MoveType.REMOVE_CHIP:
      return `Removed chip from opponent from ${positionString}`
  }
}

// Export all functions
export {
  hasClosedMill,
  getNextPlayer,
  getNextGameStateDependingOnPieces,
  isPlayerFlying,
  isAdjacentField,
  getEmptyBoard,
  getPlayerNameString,
  getPlayerElement,
  getMoveString,
}
