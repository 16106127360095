import React from 'react'
import './template.scss'

interface IProps {
  children?: any
}

const Template = (props: IProps): React.JSX.Element => {
  return (
    <div className="template">
      <div className="background" />
      <div className="content">{props.children}</div>
    </div>
  )
}
export default Template
