import { BoardCircle, BoardField } from '../types/boardTypes'
import type { ICoordinates } from '../types/boardTypes'

/*
 ** Some constants defining the position of fields on the board
 ** Related to the vmin property of CSS
 */
export const BOARD_WIDTH = 70
export const BOARD_HEIGHT = 70
export const FIELD_DISTANCE = 10
export const FIELD_SIZE = 5
export const EMPTY_FIELD_SIZE = 1.5

// Other constants for board
export const FIELDS_PER_CIRCLE = 8
export const NUMBER_OF_CIRCLES = 3

// Mapping of a specific field to coordinates
export const fieldCoordinatesMap: {
  [key in BoardField]: ICoordinates
} = {
  [BoardField.TopLeft]: { x: -FIELD_DISTANCE, y: FIELD_DISTANCE },
  [BoardField.TopCenter]: { x: 0, y: FIELD_DISTANCE },
  [BoardField.TopRight]: { x: FIELD_DISTANCE, y: FIELD_DISTANCE },
  [BoardField.CenterRight]: { x: FIELD_DISTANCE, y: 0 },
  [BoardField.BottomRight]: { x: FIELD_DISTANCE, y: -FIELD_DISTANCE },
  [BoardField.BottomCenter]: { x: 0, y: -FIELD_DISTANCE },
  [BoardField.BottomLeft]: { x: -FIELD_DISTANCE, y: -FIELD_DISTANCE },
  [BoardField.CenterLeft]: { x: -FIELD_DISTANCE, y: 0 },
}

// Mapping of the multiplicator for the coordinates depending on the circle the field is in
export const circleCoordinatesMultiplicator: {
  [key in BoardCircle]: number
} = {
  [BoardCircle.Outer]: 3,
  [BoardCircle.Middle]: 2,
  [BoardCircle.Inner]: 1,
}

// Mapping the size of a circle depending on the repsective circle position
export const circleSizeMap: {
  [key in BoardCircle]: number
} = {
  [BoardCircle.Outer]: 60,
  [BoardCircle.Middle]: 40,
  [BoardCircle.Inner]: 20,
}
