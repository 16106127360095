import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../app/store'
import { GameState, type IBoard, type IPosition } from '../../types/boardTypes'
import { Player } from '../../types/fieldTypes'
import {
  type IMoveChip,
  type IPlaceChip,
  type IRemoveChip,
  type ISelectChipToMove,
} from '../../types/moveTypes'
import {
  getEmptyBoard,
  getNextGameStateDependingOnPieces,
  getNextPlayer,
  hasClosedMill,
} from '../../utils/gameUtils'
import { PIECES_REMOVED_FOR_FLYING } from '../../constants/gameConstants'

export interface BoardState {
  // Contains information about the board (e.g. where which chips are placed)
  board: IBoard
  // The current state of the game (e.g. placing pieces, moving pieces, flying)
  gameState: GameState
  // The player whose turn it is
  playersTurn: Player
  // The number of pieces that have been placed by each player
  placedPieces: {
    [Player.PlayerOne]: number
    [Player.PlayerTwo]: number
  }
  // This variable is used to temporarily store the chip that should be moved
  selectionToMove: IPosition | null
  // The number of pieces that have been removed
  piecesRemoved: {
    [Player.PlayerOne]: number
    [Player.PlayerTwo]: number
  }
}

const initialState: BoardState = {
  board: getEmptyBoard(),
  gameState: GameState.PLACING_PIECES,
  playersTurn: Player.PlayerOne,
  placedPieces: {
    [Player.PlayerOne]: 0,
    [Player.PlayerTwo]: 0,
  },
  selectionToMove: null,
  piecesRemoved: {
    [Player.PlayerOne]: 0,
    [Player.PlayerTwo]: 0,
  },
}

export const boardSlice = createSlice({
  name: 'board',
  initialState,
  reducers: {
    placeChip: (state, action: PayloadAction<IPlaceChip>) => {
      // Place the chip on the board
      state.board[action.payload.position.circle][
        action.payload.position.field
      ].player = state.playersTurn

      // Increase the number of placed pieces per player
      state.placedPieces[state.playersTurn]++

      // Check if there a mill has been created with the current move
      if (
        hasClosedMill({
          board: state.board,
          player: state.playersTurn,
          positionToCheck: action.payload.position,
        })
      ) {
        // If the player has a mill, we switch to the removing pieces state
        state.gameState = GameState.REMOVING_PIECES
        return
      } else {
        // If there is no mill, change the player
        state.playersTurn = getNextPlayer(state.playersTurn)
      }

      // Get the next game state
      state.gameState = getNextGameStateDependingOnPieces(state)
    },
    removeChip: (state, action: PayloadAction<IRemoveChip>) => {
      const otherPlayer = getNextPlayer(state.playersTurn)
      state.board[action.payload.position.circle][
        action.payload.position.field
      ] = {
        player: null,
      }
      state.piecesRemoved[otherPlayer]++

      if (state.piecesRemoved[otherPlayer] >= PIECES_REMOVED_FOR_FLYING + 1) {
        // game win
        state.gameState = GameState.GAME_OVER
      } else {
        state.playersTurn = getNextPlayer(state.playersTurn)
        state.gameState = getNextGameStateDependingOnPieces(state)
      }
    },
    selectChipToMove: (state, action: PayloadAction<ISelectChipToMove>) => {
      state.selectionToMove = action.payload.position
      state.gameState = GameState.PIECE_FOR_MOVING_SELECTED
    },
    moveChip: (state, action: PayloadAction<IMoveChip>) => {
      // Abort if no field is selected
      if (state.selectionToMove === null) {
        return
      }

      // clear selected field
      state.board[state.selectionToMove.circle][state.selectionToMove.field] = {
        player: null,
      }
      state.selectionToMove = null

      // set chip at board
      state.board[action.payload.position.circle][
        action.payload.position.field
      ] = {
        player: state.playersTurn,
      }

      // check for mill
      if (
        hasClosedMill({
          board: state.board,
          player: state.playersTurn,
          positionToCheck: action.payload.position,
        })
      ) {
        // If the player has a mill, we switch to the removing pieces state
        state.gameState = GameState.REMOVING_PIECES
        return
      } else {
        // If there is no mill, change the player
        state.playersTurn = getNextPlayer(state.playersTurn)
      }
      state.gameState = getNextGameStateDependingOnPieces(state)
    },
    resetGame: state => {
      state.board = getEmptyBoard()
      state.gameState = GameState.PLACING_PIECES
      state.playersTurn = Player.PlayerOne
      state.placedPieces = {
        [Player.PlayerOne]: 0,
        [Player.PlayerTwo]: 0,
      }
      state.selectionToMove = null
      state.piecesRemoved = {
        [Player.PlayerOne]: 0,
        [Player.PlayerTwo]: 0,
      }
    },
    setMemento: (state, action: PayloadAction<BoardState>) => {
      state.board = action.payload.board
      state.gameState = action.payload.gameState
      state.playersTurn = action.payload.playersTurn
      state.placedPieces = action.payload.placedPieces
      state.selectionToMove = action.payload.selectionToMove
      state.piecesRemoved = action.payload.piecesRemoved
    },
  },
})

/**
 * Export actions
 */
export const {
  placeChip,
  removeChip,
  selectChipToMove,
  moveChip,
  resetGame,
  setMemento,
} = boardSlice.actions

/**
 * Export selectors
 */
export const selectBoard = (state: RootState): IBoard => state.board.board

export const selectGameState = (state: RootState): GameState =>
  state.board.gameState

export const selectPlayersTurn = (state: RootState): Player =>
  state.board.playersTurn

export const selectMemento = (state: RootState): BoardState => state.board

export const selectBoardState = (state: RootState): BoardState => state.board

export const selectSelectionToMove = (state: RootState): IPosition | null =>
  state.board.selectionToMove

export const selectPiecesRemovedP1 = (state: RootState): number =>
  state.board.piecesRemoved[Player.PlayerOne]

export const selectPiecesRemovedP2 = (state: RootState): number =>
  state.board.piecesRemoved[Player.PlayerTwo]

export const selectPlacedPiecesP1 = (state: RootState): number =>
  state.board.placedPieces[Player.PlayerOne]

export const selectPlacedPiecesP2 = (state: RootState): number =>
  state.board.placedPieces[Player.PlayerTwo]

/**
 * Export default reducer
 */
export default boardSlice.reducer
