// The player enum is used to determine which player is currently active
export enum Player {
  PlayerOne = 1,
  PlayerTwo = 2,
}

// The field information interface contains field specific information (e.g. which player is on the field)
export interface IFieldInformation {
  player: Player | null
}
