import { type Middleware } from '@reduxjs/toolkit'
import { type RootState } from '../app/store'
import { type BoardState, selectMemento } from '../features/board/boardSlice'
import { resetHistory, saveToHistory } from '../features/history/historySlice'
import { type IMove } from '../types/moveTypes'

/**
 * Stores the state of the board into the history
 */
export const BoardMemento: Middleware<any, RootState> =
  store => next => action => {
    // Middleware only applies to actions related to the board

    if (action.type.startsWith('board/resetGame') === true) {
      // Reseting the game is handled differently than normal move
      // Need to reset the whole history
      store.dispatch(resetHistory())
    } else if (action.type.startsWith('board/setMemento') === true) {
      // Setting the board state to a memento is also handled differently, since we don't want to store something in the history
    } else if (action.type.startsWith('board/') === true) {
      // Get the memento
      const memento: BoardState = selectMemento(store.getState())

      // The current move is also needed to be stored
      const move: IMove = action.payload

      // Dispatch an action to store it to the history (Note: cannot use hooks here!)
      // Need to store move and board state
      store.dispatch(saveToHistory({ move, boardState: memento }))
    }

    return next(action)
  }
