import { type IPosition } from './boardTypes'

// The type of a move
export enum MoveType {
  PLACE_CHIP = 'PLACE_CHIP',
  SELECT_CHIP_TO_MOVE = 'SELECT_CHIP_TO_MOVE',
  MOVE_CHIP = 'MOVE_CHIP',
  REMOVE_CHIP = 'REMOVE_CHIP',
}

export interface IMove {
  type: MoveType
  position: IPosition
}

export interface IPlaceChip extends IMove {
  readonly type: MoveType.PLACE_CHIP
}
export function createPlaceChip(position: IPosition): IPlaceChip {
  return {
    type: MoveType.PLACE_CHIP,
    position,
  }
}

export interface ISelectChipToMove extends IMove {
  readonly type: MoveType.SELECT_CHIP_TO_MOVE
}
export function createSelectChipToMove(position: IPosition): ISelectChipToMove {
  return {
    type: MoveType.SELECT_CHIP_TO_MOVE,
    position,
  }
}

export interface IMoveChip extends IMove {
  readonly type: MoveType.MOVE_CHIP
}
export function createMoveChip(position: IPosition): IMoveChip {
  return {
    type: MoveType.MOVE_CHIP,
    position,
  }
}

export interface IRemoveChip extends IMove {
  readonly type: MoveType.REMOVE_CHIP
}
export function createRemoveChip(position: IPosition): IRemoveChip {
  return {
    type: MoveType.REMOVE_CHIP,
    position,
  }
}
