import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './rootReducer'
import { BoardMemento } from '../middleware/boardMemento'
import thunkMiddleware from 'redux-thunk'

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(BoardMemento).concat(thunkMiddleware),
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof rootReducer>
