import React, { useCallback } from 'react'
import './field.scss'
import {
  GameState,
  type IBoard,
  type ICoordinates,
  type IPosition,
} from '../../types/boardTypes'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  moveChip,
  placeChip,
  removeChip,
  selectBoard,
  selectBoardState,
  selectChipToMove,
  selectGameState,
  selectPlayersTurn,
  selectSelectionToMove,
} from '../../features/board/boardSlice'
import { Player } from '../../types/fieldTypes'
import { FIELD_SIZE } from '../../constants/boardConstants'
import classNames from 'classnames'
import {
  createMoveChip,
  createPlaceChip,
  createRemoveChip,
  createSelectChipToMove,
} from '../../types/moveTypes'
import {
  getNextPlayer,
  hasClosedMill,
  isAdjacentField,
  isPlayerFlying,
} from '../../utils/gameUtils'

interface IProps {
  position: IPosition
  coordinates: ICoordinates
}

const Field = (props: IProps): React.JSX.Element => {
  // The board contains information about all the stated and is stored in the redux store
  const board: IBoard = useAppSelector(selectBoard)
  const fieldInformation = board[props.position.circle][props.position.field]
  const state = useAppSelector(selectGameState)
  const playersTurn = useAppSelector(selectPlayersTurn)
  const selectionToMove = useAppSelector(selectSelectionToMove)
  const boardState = useAppSelector(selectBoardState)

  // The game state gives information about the current state of the game
  const gameState = useAppSelector(selectGameState)

  // Used to dispatch actions to the redux store
  const dispatch = useAppDispatch()

  // Checks if the field is hoverable
  const showChipOnHover = useCallback((): boolean => {
    return (
      state === GameState.PLACING_PIECES && fieldInformation.player === null
    )
  }, [fieldInformation, state])

  // Checks if the field is clickable
  const isClickable = useCallback((): boolean => {
    // If the field is clickable depends on the state
    switch (gameState) {
      case GameState.PLACING_PIECES:
        return fieldInformation.player === null
      case GameState.SELECT_PIECE_TO_MOVE:
        return fieldInformation.player === playersTurn
      case GameState.REMOVING_PIECES:
        return fieldInformation.player !== playersTurn
      default:
        return false
    }
  }, [state, fieldInformation])

  const getOccupationClassName = useCallback(() => {
    if (fieldInformation.player === Player.PlayerOne) {
      return 'player-one'
    } else if (fieldInformation.player === Player.PlayerTwo) {
      return 'player-two'
    } else {
      return 'empty'
    }
  }, [fieldInformation])

  // Handles the clicking on a field when the player is placing a piece
  const handlePlacingPiece = (): void => {
    // If the field is already occupied, do nothing
    if (fieldInformation.player !== null) {
      return
    }

    // Otherwise the move is valid and we can dispatch an action
    dispatch(placeChip(createPlaceChip(props.position)))
  }

  const handleRemovingPieces = (): void => {
    // If the field is not occupied, do nothing
    if (fieldInformation.player === null) {
      return
    }
    // If field is occupied by own chip
    if (fieldInformation.player === playersTurn) {
      return
    }

    // must not remove chip from mill
    const otherPlayer = getNextPlayer(playersTurn)
    if (
      hasClosedMill({
        board: board,
        player: otherPlayer,
        positionToCheck: props.position,
      })
    ) {
      return
    }

    dispatch(removeChip(createRemoveChip(props.position)))
  }

  const selectPieceToMove = (): void => {
    // player can only move own piece
    if (fieldInformation.player !== playersTurn) {
      return
    }
    dispatch(selectChipToMove(createSelectChipToMove(props.position)))
  }

  const movePieceTo = (): void => {
    // Cannot move if no field selected
    if (selectionToMove === null) {
      return
    }

    // cannot move to occupied field
    if (fieldInformation.player !== null) {
      return
    }

    // check whether field is adjacent or player can fly
    if (!isPlayerFlying(boardState)) {
      // check if field is adjacent
      if (!isAdjacentField(selectionToMove, props.position)) {
        return
      }
    }

    dispatch(moveChip(createMoveChip(props.position)))
  }
  // Handle a click on the field
  const onFieldClick = (): void => {
    // What happens on a click depends on the state we are currently in
    switch (gameState) {
      case GameState.PLACING_PIECES:
        handlePlacingPiece()
        break
      case GameState.SELECT_PIECE_TO_MOVE:
        selectPieceToMove()
        break
      case GameState.PIECE_FOR_MOVING_SELECTED:
        movePieceTo()
        break
      case GameState.REMOVING_PIECES:
        handleRemovingPieces()
        break
    }
  }

  // Render the field
  return (
    <div
      className={classNames({
        'field-container': true,
        'player-one-turn': playersTurn === Player.PlayerOne,
        'player-two-turn': playersTurn === Player.PlayerTwo,
        clickable: isClickable(),
        'show-chip-on-hover': showChipOnHover(),
        [getOccupationClassName()]: true,
        'selected-to-move': selectionToMove === props.position,
      })}
      style={{
        transform: `translate(${props.coordinates.x}vmin, ${props.coordinates.y}vmin)`,
        width: `${FIELD_SIZE}vmin`,
        height: `${FIELD_SIZE}vmin`,
      }}
      onClick={onFieldClick}
    >
      <div className={`field ${getOccupationClassName()}`} />
    </div>
  )
}

export default Field
