import React, { useCallback } from 'react'
import './history.scss'
import { useAppSelector } from '../../app/hooks'
import { selectHistory, selectPositionInHistory } from './historySlice'
import { getMoveString, getPlayerElement } from '../../utils/gameUtils'
import { type IHistoryElement } from '../../types/historyTypes'
import classNames from 'classnames'

const History = (): React.JSX.Element => {
  // History contains all the past board states and moves
  const history: IHistoryElement[] = useAppSelector(selectHistory)

  // The position of the current active state in history (points to the next future move)
  const positionInHistory: number = useAppSelector(selectPositionInHistory)

  // Render the history entries
  const renderHistoryEntries = useCallback((): React.JSX.Element[] => {
    const elements: React.JSX.Element[] = []

    history.forEach((historyElem: IHistoryElement, index: number) => {
      elements.push(
        <div
          className={classNames({
            'history-entry': true,
            inactive: index >= positionInHistory,
          })}
          key={index}
        >
          <strong>{index + 1}. </strong>
          {getPlayerElement(historyElem.boardState.playersTurn)}
          <span>: </span>
          {getMoveString(historyElem.move)}

          {index >= positionInHistory && (
            <span className="undone-move"> (move undone)</span>
          )}
        </div>,
      )
    })

    return [...elements].reverse()
  }, [history, positionInHistory])

  return (
    <div className="history-container">
      <div className="history-inner">
        {history.length === 0 && (
          <div className="history-entry">
            <strong>History is empty. </strong>
            <span>No moves made yet ...</span>
          </div>
        )}

        {renderHistoryEntries()}
      </div>
    </div>
  )
}

export default History
