/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/prefer-ts-expect-error */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useRef } from 'react'
import './controls.scss'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from '@mui/material'
import History from '../../features/history/history'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  type BoardState,
  resetGame,
  selectBoardState,
  selectGameState,
  selectPiecesRemovedP1,
  selectPiecesRemovedP2,
  selectPlacedPiecesP1,
  selectPlacedPiecesP2,
  selectPlayersTurn,
  setMemento,
} from '../../features/board/boardSlice'
import { GameState } from '../../types/boardTypes'
import { getPlayerNameString, getPlayerElement } from '../../utils/gameUtils'
import {
  undoMove,
  redoMove,
  selectCanRedoMove,
  selectCanUndoMove,
  selectHistory,
  selectPositionInHistory,
  setLoadedHistory,
} from '../../features/history/historySlice'

const Controls = (): React.JSX.Element => {
  const boardState = useAppSelector(selectBoardState)
  const history = useAppSelector(selectHistory)
  const positionInHistory = useAppSelector(selectPositionInHistory)
  const gameState = useAppSelector(selectGameState)
  const player = useAppSelector(selectPlayersTurn)
  const piecesRemovedP1 = useAppSelector(selectPiecesRemovedP1)
  const piecesRemovedP2 = useAppSelector(selectPiecesRemovedP2)
  const placedPiecesP1 = useAppSelector(selectPlacedPiecesP1)
  const placedPiecesP2 = useAppSelector(selectPlacedPiecesP2)
  const [openGameOverDialog, setOpenGameOverDialog] = React.useState(false)
  const [openResetConfirmationDialog, setOpenResetConfirmationDialog] =
    React.useState(false)
  const dispatch = useAppDispatch()

  // Indicating if moves can be undone/redone
  const canUndoMove = useAppSelector(selectCanUndoMove)
  const canRedoMove = useAppSelector(selectCanRedoMove)

  // Ref for the file input
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  // Handle the reset of the game
  const handleReset = (): void => {
    dispatch(resetGame())
    setOpenResetConfirmationDialog(false)
    setOpenGameOverDialog(false)
  }

  // Check for the game state to change to game over
  useEffect(() => {
    if (gameState === GameState.GAME_OVER) {
      setOpenGameOverDialog(true)
    }
  }, [gameState])
  const handleSave = (): void => {
    const exportObj = {
      positionInHistory,
      boardState,
      history,
      piecesRemoved: [piecesRemovedP1, piecesRemovedP2],
      placedPieces: [placedPiecesP1, placedPiecesP2],
    }

    const link = document.createElement('a')
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(exportObj, null, 2),
    )}`
    link.href = jsonString
    link.download = `nine_mens_morris_saved_game.json`

    link.click()
  }

  // Render the dialog that is being rendered to confirm the reset of the game
  const renderResetConfirmationDialog = (): React.JSX.Element => {
    return (
      <Dialog
        open={openResetConfirmationDialog}
        onClose={() => {
          setOpenResetConfirmationDialog(false)
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Reset game?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you really want to reset the game? This will delete the current
            game state.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenResetConfirmationDialog(false)
            }}
          >
            Abort
          </Button>
          <Button onClick={handleReset}>Reset game</Button>
        </DialogActions>
      </Dialog>
    )
  }

  // Render the dialog that is being displayed if the game is over
  const renderGameOverDialog = (): React.JSX.Element => {
    return (
      <Dialog
        open={openGameOverDialog}
        onClose={() => {
          setOpenGameOverDialog(false)
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Game Over!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Congratulation {getPlayerNameString(player)}, you win!!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenGameOverDialog(false)
            }}
          >
            Close
          </Button>
          <Button onClick={handleReset}>Reset game</Button>
        </DialogActions>
      </Dialog>
    )
  }

  // Render the button to control the game (save, load, restart)
  const renderGameControlButtons = (): React.JSX.Element => {
    return (
      <>
        <Grid item xs={12} sm={6}>
          <Button variant="contained" onClick={handleSave} fullWidth>
            Save Game
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div id={'loadGame'}>
            <span>Restore game:</span>
            <input
              ref={fileInputRef}
              type="file"
              onChange={e => {
                showFile(e)
              }}
            />
          </div>
        </Grid>

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="warning"
            onClick={() => {
              setOpenResetConfirmationDialog(true)
            }}
            fullWidth
          >
            Restart game
          </Button>
        </Grid>
      </>
    )
  }

  // Render the control info that shows information about the current move that has to be made
  const renderGameControlInfo = (): React.JSX.Element => {
    return (
      <>
        <Grid item xs={12}>
          <strong>Next: </strong>
          {getPlayerElement(player)}

          <br />

          {gameState === GameState.PLACING_PIECES && (
            <>
              <strong>Placing Pieces:</strong> You can place a piece on the
              board by clicking on an empty field.
            </>
          )}

          {gameState === GameState.SELECT_PIECE_TO_MOVE && (
            <>
              <strong>Moving Pieces:</strong> Select piece you want to move
            </>
          )}

          {gameState === GameState.PIECE_FOR_MOVING_SELECTED && (
            <>
              <strong>Moving Pieces:</strong> Select the field you want to move
              to.
            </>
          )}

          {gameState === GameState.REMOVING_PIECES && (
            <>
              <strong>Removing Pieces:</strong> Select piece you want to remove
            </>
          )}

          {gameState === GameState.GAME_OVER && (
            <>
              <strong>Game over:</strong> restart the game?
            </>
          )}
        </Grid>
      </>
    )
  }

  // Handle a click on the undo button
  const handleUndoClick = (): void => {
    if (!canUndoMove) return

    dispatch(undoMove())
  }

  // Handle a click on the redo button
  const handleRedoClick = (): void => {
    if (!canRedoMove) return

    dispatch(redoMove())
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const showFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const reader = new FileReader()
    reader.onload = async e => {
      if (e.target === null) return
      const text: string | ArrayBuffer | null = e.target.result
      // console.log(text)
      if (typeof text === 'string') {
        const parsed = JSON.parse(text)
        // console.log(parsed.positionInHistory)
        // console.log(parsed.boardState)
        // console.log(parsed.history)
        // console.log(parsed.boardState.playersTurn)
        // console.log(parsed.boardState.gameState)
        // console.log(parsed.placedPieces)
        // console.log(parsed.piecesRemoved)

        const memento: BoardState = {
          board: parsed.boardState.board,
          gameState: parsed.boardState.gameState,
          playersTurn: parsed.boardState.playersTurn,
          placedPieces: parsed.boardState.placedPieces,
          selectionToMove: null,
          piecesRemoved: parsed.boardState.piecesRemoved,
        }

        dispatch(setLoadedHistory(parsed))
        dispatch(setMemento(memento))

        // Reset upon success
        if (fileInputRef !== null && fileInputRef.current !== null) {
          fileInputRef.current.value = ''
        }
      }
    }
    if (e.target.files === null) return
    reader.readAsText(e.target.files[0])
  }

  // Render the buttons to control the history (undo , redo)
  const renderHistoryControlButtons = (): React.JSX.Element => {
    return (
      <>
        <Grid item xs={12} sm={6}>
          <Button
            variant="outlined"
            onClick={handleUndoClick}
            fullWidth
            disabled={!canUndoMove}
          >
            Undo
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            variant="outlined"
            onClick={handleRedoClick}
            fullWidth
            disabled={!canRedoMove}
          >
            Redo
          </Button>
        </Grid>
      </>
    )
  }

  // Render the controls component
  return (
    <div className="controls">
      <Grid container spacing={2}>
        <Grid item xs={12} textAlign="center">
          <h2>🎲 Game controls 🎮</h2>
        </Grid>

        {/* Buttons to control the game */}
        {renderGameControlButtons()}

        {/* Dialog to confirm the reset of the game */}
        {renderResetConfirmationDialog()}

        <Grid item xs={12} textAlign="center">
          <h4>👍 Control info ℹ️</h4>
        </Grid>

        {/* Render the info for the next move and the players turn */}
        {renderGameControlInfo()}

        {/* Render the dialog that is being showed on game Over */}
        {renderGameOverDialog()}

        <Grid item xs={12} textAlign="center">
          <h4>📖 History 🔄</h4>
        </Grid>

        {/* Redner the buttons to control the history */}
        {renderHistoryControlButtons()}
      </Grid>

      <History />
    </div>
  )
}

export default Controls
