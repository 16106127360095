import { type IFieldInformation } from './fieldTypes'

// The respective circle on the field
export enum BoardCircle {
  Outer = 0,
  Middle = 1,
  Inner = 2,
}

// The position of a field in a specific circle
export enum BoardField {
  TopLeft = 0,
  TopCenter = 1,
  TopRight = 2,
  CenterRight = 3,
  BottomRight = 4,
  BottomCenter = 5,
  BottomLeft = 6,
  CenterLeft = 7,
}

// The direction of a line on the board
export enum Direction {
  Top,
  Right,
  Bottom,
  Left,
}

// The state of the game
export enum GameState {
  PLACING_PIECES,
  REMOVING_PIECES,
  SELECT_PIECE_TO_MOVE,
  PIECE_FOR_MOVING_SELECTED,
  GAME_OVER,
}

// Interface describing coordinates on the board
export interface ICoordinates {
  x: number
  y: number
}

// Interface describing the board. This contains the information about which elemnts are located where on the board
export type IBoard = {
  [key in BoardCircle]: {
    [key in BoardField]: IFieldInformation
  }
}

// Interface describing the position of a field on the board
export interface IPosition {
  circle: BoardCircle
  field: BoardField
}
