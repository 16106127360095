import React from 'react'
import './board.scss'
import {
  BOARD_HEIGHT,
  BOARD_WIDTH,
  FIELDS_PER_CIRCLE,
  FIELD_SIZE,
  NUMBER_OF_CIRCLES,
  circleCoordinatesMultiplicator,
  circleSizeMap,
  fieldCoordinatesMap,
} from '../../constants/boardConstants'
import {
  type BoardCircle,
  type ICoordinates,
  type IPosition,
} from '../../types/boardTypes'
import Field from '../../components/field/field'
import Controls from '../../components/controls/controls'

const Board = (): React.JSX.Element => {
  /* Style for the board defining its size */
  const boardStyle = {
    width: `${BOARD_WIDTH}vmin`,
    height: `${BOARD_HEIGHT}vmin`,
  }

  /* Get the coordinates of a field on the board */
  const getCoordinatesOfField = (position: IPosition): ICoordinates => {
    /* Get the simpe coordinates of the field without considering the circle the field is in */
    const fieldCoordinates = fieldCoordinatesMap[position.field]
    const coordinates: ICoordinates = {
      x: fieldCoordinates.x ?? 0,
      y: fieldCoordinates.y ?? 0,
    }

    /* y-axis is inverted, since negative y means placing field on top */
    coordinates.y *= -1

    /* Now also consider the circle the field is in */
    coordinates.x *= circleCoordinatesMultiplicator[position.circle]
    coordinates.y *= circleCoordinatesMultiplicator[position.circle]

    /* Need to correct offset so that fields are centered */
    coordinates.x -= FIELD_SIZE / 2.0
    coordinates.y -= FIELD_SIZE / 2.0

    return coordinates
  }

  const gridBottomLabels = ['A', 'B', 'C', 'D', 'E', 'F', 'G']
  const gridLeftLabels = ['7', '6', '5', '4', '3', '2', '1']

  /* Return the rendered board */
  return (
    <div className="board-container">
      <div className="board" style={boardStyle}>
        {/* Render the circles */}
        {Array.from({ length: NUMBER_OF_CIRCLES }).map((_, circleIndex) => {
          /* Calculating the size of the circle */
          const circleSize = circleSizeMap[circleIndex as BoardCircle] ?? 0
          const circleStyle = {
            width: `${circleSize}vmin`,
            height: `${circleSize}vmin`,
          }

          return (
            <div
              key={`circle-${circleIndex}`}
              style={circleStyle}
              className="circle"
            ></div>
          )
        })}
        {/* Render the fields */}
        {Array.from({ length: NUMBER_OF_CIRCLES }).map((_, circleIndex) => {
          return Array.from({ length: FIELDS_PER_CIRCLE }).map(
            (_, fieldIndex) => {
              const position: IPosition = {
                circle: circleIndex,
                field: fieldIndex,
              }

              /* Calculate the position of the field on the board */
              const coordinates: ICoordinates = getCoordinatesOfField(position)

              /* Return the respective field to render */
              return (
                <Field
                  key={`field-${fieldIndex}`}
                  position={position}
                  coordinates={coordinates}
                />
              )
            },
          )
        })}
        {/* Render the lines connecting the circles */}
        <div className="line line-horizontal line-left"></div>
        <div className="line line-horizontal line-right"></div>
        <div className="line line-vertical line-top"></div>
        <div className="line line-vertical line-bottom"></div>

        {/* Render the grid */}
        <div className="grid bottom">
          {gridBottomLabels.map(label => (
            <div key={label}>{label}</div>
          ))}
        </div>
        <div className="grid left">
          {gridLeftLabels.map(label => (
            <div key={label} className="row">
              <div className="col">{label}</div>
            </div>
          ))}
        </div>
      </div>

      <div className="board-controls">
        <Controls />
      </div>
    </div>
  )
}

export default Board
