// rootReducer.js
import { combineReducers } from '@reduxjs/toolkit'
import boardReducer from '../features/board/boardSlice'
import historyReducer from '../features/history/historySlice'

const rootReducer = combineReducers({
  board: boardReducer,
  history: historyReducer,
})

export default rootReducer
